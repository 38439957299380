
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { QuestionModalComponent } from './questionModal/questionmodal.component';
import { ResultModalComponent } from './resultModal/resultModal.component';
import { HttpClientModule } from '@angular/common/http'
import { TypingComponent } from './typing/typing.component';

@NgModule({
  declarations: [
    AppComponent,
    QuestionModalComponent,
    ResultModalComponent,
    TypingComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
 