import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  showQuestionModal = false;

  showQuestionModalFn(): void {
    this.showQuestionModal = true;
  }

  closeModalFn(): void {
    // Add double click to close
    this.showQuestionModal = false;
  }
}
