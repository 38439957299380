import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';


// import * as dotenv from 'dotenv';
// dotenv.config();


@Injectable({
  providedIn: 'root'
})
export class Gpt3Service {
  
  // private apiKey = '' // Replace with your API key
  private apiUrl = 'https://api.openai.com/v1/completions'; // Proxy server URL + OpenAI API URL
  // private apiKey= environment.API_KEY
  private apiKey = "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"

  // Use apiKey in your application logic
  

  // private apiKey = process.env['API_KEY'] || 'default-value-if-not-defined';
  // private apiKey = process.env['API_KEY'];
  // private apiKey = environment.API_KEY;
  
  constructor(private http: HttpClient) {}
  
  generateText(prompt: string, maxTokens: number, temperature: number): Observable<any> {
    
    const body = {
      model: 'text-davinci-003',
      prompt,
      max_tokens: maxTokens,
      temperature: temperature,
    };

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.apiKey}`
    });
    const options = { headers };
    return this.http.post<any>(this.apiUrl, body, options);
    // Add loop if null

    
  }

  // generateText2(prompt: string, temperature: number, maxTokens: number, topP: number, frequencyPenalty: number, presencePenalty: number) {
  //   const body = { prompt, temperature, max_tokens: maxTokens, top_p: topP, frequency_penalty: frequencyPenalty, presence_penalty: presencePenalty };
  //   const headers = {
  //         'Content-Type': 'application/json',
  //         Authorization: `Bearer ${this.apiKey}`
  //       };
  //   const options = { headers: headers };
  //   return this.http.post<string>(this.apiUrl, JSON.stringify(body), options);
  // }
}
