import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-typing-component',
  templateUrl: './typing.component.html',
  styleUrls: ['./typing.component.scss']
})

export class TypingComponent implements OnInit {
  displayText: string = '';
  isCursorVisible: boolean = true;
  cursorInterval: NodeJS.Timer | undefined;
  typingInterval: NodeJS.Timer | undefined;
  texts: string[] = ["Let's keep our heads up!", ':)'];
  currentIndex: number = 0;

  ngOnInit() {
    this.startTyping();
  }

  startTyping() {
    const text = this.texts[this.currentIndex];
    let index = 0;

    this.typingInterval = setInterval(() => {
      this.displayText += text[index];
      index++;

      if (index === text.length) {
        clearInterval(this.typingInterval);
        setTimeout(() => {
          this.startErasing();
        }, 1000); // Wait for 1 second before erasing the text
      }
    }, 150);

    this.cursorInterval = setInterval(() => {
      this.isCursorVisible = !this.isCursorVisible;
    }, 500);
  }

  startErasing() {
    let index = this.displayText.length;

    this.typingInterval = setInterval(() => {
      this.displayText = this.displayText.slice(0, index);
      index--;

      if (index === -1) {
        clearInterval(this.typingInterval);
        this.currentIndex = (this.currentIndex + 1) % this.texts.length;
        setTimeout(() => {
          this.startTyping();
        }, 200); // Wait for 1 second before starting the next text
      }
    }, 100);
  }

  ngOnDestroy() {
    clearInterval(this.typingInterval);
    clearInterval(this.cursorInterval);
  }
}
