import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Gpt3Service } from '../services/gpt3.service';
import questions from '../../data/questions.json';

interface QuestionData {
  questions: {
    question: string;
    choices: string[];
  }[];
}

@Component({
  selector: 'app-questionModal',
  templateUrl: './questionModal.component.html',
  styleUrls: ['./questionModal.component.scss']
})
export class QuestionModalComponent {
  @Input() showModal: boolean = false;
  @Output() closeModal = new EventEmitter<void>();
  showResultModal = false;
  questions: any[] = [];
  randomQuestions: any[] = []
  questionData: any;
  currentIndex = 0;
  currentQuestion: any;
  text: string = 'writing...';

  constructor(private gpt3Service: Gpt3Service) {}

  ngOnInit(): void {
  console.log('questions:', questions);
  this.currentQuestion = questions.questions[0];
  if (questions && questions.questions) {
    this.randomQuestions = this.shuffleArray(questions.questions).slice(0, 5);
    this.currentQuestion = this.randomQuestions[0];
    }
  }

  shuffleArray(array: any[]) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  }

  onQuestionPageClicked(answer: string) {
    // handle user's answer for the current question
    console.log('Answer:', answer);
    // add user's answer to current question object
    this.currentQuestion.userAnswer = answer;
  
    // move to the next question
    this.currentIndex++;
    if (this.randomQuestions && this.currentIndex < this.randomQuestions.length) {
      this.currentQuestion = this.randomQuestions[this.currentIndex];
    } else {
      // no more questions, close the modal and log user answers
      this.closeModal.emit();
      const userAnswers = this.randomQuestions.map((question) => {
        return {
          question: question.question,
          userAnswer: question.userAnswer
        };
      });

      console.log('User Answers:', JSON.stringify(userAnswers));
      const prompt = `Compose a positive and uplifting message based for a Generation Z and X type of language. Your message should be around 69 words long and have a kind, empathetic tone. Encourage the reader. Do not use the same words that appear in the questions to ensure creativity in your response. write it in first perspective. don't say any same words that are in the answers: ${JSON.stringify(userAnswers)}.`;
      const fpPrompt = `Use only users answers from the questions and using the answers compose a positive message using language way Generation Z and X usually write. The message should be approximately 69 words long, use a kind, empathic, encouraging tone. Make it one unitied entity. ${JSON.stringify(userAnswers)}.`;
      const maxTokens = 333;
      const temperature = 0.75;

      this.gpt3Service.generateText(fpPrompt, maxTokens, temperature)
        .subscribe(response => {
          this.text = response.choices[0].text;
          
        });
    this.showResultModal = true;
    
      this.closeModal.emit();

    }
  }

  showResultModalFn(): void {
    this.showResultModal = true;
  }
}

