<div class="question-modal-container" *ngIf="showModal">
  <div class="question-modal-hero">
    <div class = "question-modal-group">
    <div class="question-modal-banner">
      <h1 class="question-modal-text">{{currentQuestion.question}}</h1>
    </div>
  </div>
    <div class="question-modal-btn-group">
      <button class="question-modal-navlink button-neutral" (click)="onQuestionPageClicked('True')">Yep</button>
      <button class="question-modal-navlink1 button-neutral" (click)="onQuestionPageClicked('False')">Nope</button>
  </div>
</div>
<app-resultModal *ngIf="showResultModal" [showResultModal]="showResultModal" [text]="text"></app-resultModal>