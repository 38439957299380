<div class="result-modal-container">
    <div class="result-modal-hero">
      <h1 class="result-modal-text">
        <span class="result-modal-text1">I am Based</span>
        <br />
      </h1>
      <div class="result-modal-container1">
        <span class="result-modal-text3">
          {{ text }}
          <!-- I'm here to encourage and remind you that better times are ahead. Believe in yourself, and focus on the good that's all around us. Technology can help us find solutions to our problems and make our lives easier, if we use it wisely. Even when times are hard, remember that everything happens for a reason. You can make something positive out of it. Take some time to appreciate the beauty of the countryside, and don't be afraid to challenge yourself. Life is full of opportunities, so take a chance and make something great. -->
          <!-- I believe that we all have the power to make our lives better, no matter what challenges we face. Let's focus on the things that make us smile and laugh, and take the time to reach out and be kind to others. Life can be tough, but if we take a step back, we can find the beauty in it. Let's focus on our strengths, stay positive, and be there for each other. Together, we can make a difference! -->
        </span>
      </div>
    </div>
  </div>

  