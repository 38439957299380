import { Component, Input, Output } from '@angular/core'

@Component({
  selector: 'app-resultModal',
  templateUrl: './resultModal.component.html',
  styleUrls: ['./resultModal.component.scss']
})
export class ResultModalComponent {
  @Input() showResultModal: boolean = false;
  @Input() text: string = '';

  constructor() {}
}
