<div class="home-hero">
  <!-- <h1 class="home-text">Let's keep our heads up</h1> -->
  <h1 class="home-text">
    <app-typing-component></app-typing-component> 
  </h1>
  <div class="home-subtitle">
    <p>
      I'm not knowing my own strength?
    </p>
  </div>
  <div class="home-btn-group">
    <button class="home-navlink button" (click)="showQuestionModalFn()">What could it be?</button>
  </div>
  <p></p>
  <div>
    <!-- <img class="moodbased-logo" src="assets/moodbasedlogov2.jpg" alt="Image description"> -->
  </div>
</div>

<app-questionModal *ngIf="showQuestionModal" [showModal]="showQuestionModal"></app-questionModal>
